<template>
  <div>
    <b-card border-variant="light" class="custom-card">
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            STATUS NA UNIMED
          </b-col>
          <b-col>
            <div class="custom-status"><status></status></div>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Nome
          </b-col>
          <b-col>
            <b-form-input
              ref="name"
              v-model.lazy.trim="form.name"
              :state="validateField('name')"
            />
          </b-col>
          <b-col v-show="this.tooltip['name']" class="col-tooltip">
            <span id="disabled-wrapper" class="d-inline-block" tabindex="1">
              <img id="PF-1" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-1" target="PF-1">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Data de Nascimento
          </b-col>
          <b-col>
            <date-picker
              datePickerId="birthdate"
              :key="datePickerKey"
              :dataValue="form.birthdate"
              :state="validateField('birthdate')"
              @datePickerChangedValue="onChangedBirthdate"
              :required="true"
            />
          </b-col>
          <b-col v-show="this.tooltip['birthdate']" class="col-tooltip">
            <span id="disabled-wrapper" class="d-inline-block" tabindex="2">
              <img id="PF-2" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-2" target="PF-2">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            CPF
          </b-col>
          <b-col>
            <CustomMaskInput
              :initialValue="form.cpf"
              :options="cpfMaskOptions"
              :state="validateCpf('cpf')"
            ></CustomMaskInput>
          </b-col>
          <b-col v-show="this.tooltip['cpf']" class="col-tooltip">
            <span id="disabled-wrapper" class="d-inline-block" tabindex="4">
              <img id="PF-4" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-4" target="PF-4">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            RG
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.rg"              
              :state="validateField('rg')"
            />
          </b-col>
          <b-col v-show="this.tooltip['rg']" class="col-tooltip">
            <span id="disabled-wrapper" class="d-inline-block" tabindex="3">
              <img id="PF-3" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-3" target="PF-3">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Estado Civil
          </b-col>
          <b-col>
            <b-form-select
              v-model="form.maritalStatus"
              :state="validateField('maritalStatus')"
              :options="maritalStatusOptions"
              v-on:change="onChangeMaritalStatusSelect"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Escolaridade
          </b-col>
          <b-col>
            <b-form-select
              v-model="form.shooling"
              :state="validateField('schooling')"
              :options="schoolingOptions"
              v-on:change="onChangeSchoolingSelect"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            CRM
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.crm"
              type="number"
              :state="validateField('crm')"
            />
          </b-col>
          <b-col v-show="this.tooltip['crm']" class="col-tooltip">
            <span id="disabled-wrapper" class="d-inline-block" tabindex="5">
              <img id="PF-5" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-5" target="PF-5">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            PIS/NIT
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.pis"
              type="number"
              :state="validateField('pis')"
            />
          </b-col>
          <b-col v-show="this.tooltip['pis']" class="col-tooltip">
            <span id="disabled-wrapper" class="d-inline-block" tabindex="7">
              <img id="PF-7" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-7" target="PF-7">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Cartão Nacional De Saúde
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.nationalHealthCard"
              type="number"
              :state="validateField('nationalHealthCard')"
            />
          </b-col>
          <b-col
            v-show="this.tooltip['nationalHealthCard']"
            class="col-tooltip"
          >
            <span id="disabled-wrapper" class="d-inline-block" tabindex="6">
              <img id="PF-6" src="@/assets/icons/information.svg" />
            </span>
            <b-tooltip placement="top" key="PF-6" target="PF-6">
              Essa informação vai passar por análise antes de ser alterada.
            </b-tooltip>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            RAÇA - COMO VOCÊ SE AUTODECLARA?
          </b-col>
          <b-col>
            <b-form-select
              v-model="form.ethnicity"
              :state="validateField('ethnicity')"
              :options="ethnicityOptions"
              v-on:change="onChangeEthnicitySelect"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            SEXO - COMO VOCÊ SE AUTODECLARA?
          </b-col>
          <b-col>
            <b-form-select
              v-model="form.gender"
              :state="validateField('gender')"
              :options="genderOptions"
              v-on:change="onChangeGenderSelect"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            VOCÊ ESTÁ APOSENTADO(A)?
          </b-col>
          <b-col>
            <b-form-select
              v-model="form.retiree"
              :state="validateField('retiree')"
              :options="retireeOptions"
              v-on:change="onChangeRetireeSelect"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <UploadFile
              @updateFile="eventToUpdateFile"
              :multiple="true"
              :description="'Anexar documento para comprovação'"
              :msgErro="'É necessário inserir um anexo com a comprovação.'"
              :state="validateFieldFile('files')"
              :required="true"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>
    <div class="my-3" v-if="!formTouched()">
      <p class="p4 color-green-2">
        As alterações que você está prestes a realizar, terão
        <strong>impacto direto no eSocial</strong>, assim como em seu
        <strong>dados do plano de saúde</strong>, ao salvar, algumas alterações
        acontecerão de forma automática em nossa base de dados, mas fique
        tranquilo(a),
        <strong
          >você poderá alterar essas informações novamente a qualquer
          momento</strong
        >.
      </p>
    </div>
    <div class="d-flex custom-check" v-if="!formTouched()">
      <b-form-checkbox size="lg" v-model="agree"> </b-form-checkbox>
      <p class="p4 check-declaration">
        Declaro que estou ciente das regras referentes a alteração de
        informações
      </p>
    </div>

    <b-row class="my-5">
      <b-button variant="outline-primary" @click="this.onClose" class="ml-4">
        Cancelar
      </b-button>

      <b-button
        form="alter-form"
        type="button"
        class="ml-4"
        variant="primary"
        id="submitButtonText"
        :disabled="!agree || this.$store.getters.isAdmin"
        @click="this.onSubmitHandler"
      >
        Salvar
      </b-button>
    </b-row>
  </div>
</template>

<script>
import CustomMaskInput from "../../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";
import DatePicker from "../../../../../components/date-picker/DatePicker.vue";
import { formatDateToBootstrapPicker } from "../../../../../utils/date/date-utils";
import { cpf } from "cpf-cnpj-validator";
import Status from "../../../../../components/status/Status.vue";

export default {
  props: [
    "loadedFormData",
    "schoolingOptionsData",
    "ethnicityOptionsData",
    "genderOptionsData",
    "maritalStatusOptionsData",
    "onClose",
    "onSubmit",
  ],
  components: { CustomMaskInput, UploadFile, Status, DatePicker },
  mounted() {
    const loadedFormData = this.$props.loadedFormData;

    if (loadedFormData) {
      this.form = {
        ...this.form,
        ...loadedFormData,
        birthdate: formatDateToBootstrapPicker(loadedFormData.birthdate),
      };
      this.buildSchoolingOptions();
      this.buildMaritalStatusOptions();
      this.buildEthnicityOptions();
      this.buildRetiredOptions();
      this.buildGenderOptions();
      this.form.originalData = { ...this.form };
      this.datePickerKey = loadedFormData.birthdate;
    }
  },
  data() {
    return {
      datePickerKey: null,
      formSubmited: false,
      tooltip: {
        name: false,
        birthdate: false,
        rg: false,
        cpf: false,
        crm: false,
        nationalHealthCard: false,
        pis: false,
      },
      form: {
        name: null,
        nameState: null,
        birthdate: null,
        rg: null,
        cpf: null,
        maritalStatus: null,
        schooling: null,
        ethnicity: null,
        retiree: null,
        gender: null,
        crm: null,
        nationalHealthCard: null,
        pis: null,
        files: [],
        originalData: null,
      },
      agree: false,
      validations: {},
      maritalStatusOptions: [],
      schoolingOptions: [],
      ethnicityOptions: [],
      retireeOptions: [],
      genderOptions: [],
      cpfMaskOptions: {
        numericOnly: true,
        delimiters: [".", ".", "-"],
        blocks: [3, 3, 3, 2],
        onValueChanged: this.onChangeCpf,
      },
    };
  },
  methods: {
    onChangedBirthdate(value){
      this.form.birthdate = value;
    },
    onChangeCpf(e) {
      this.form.cpf = e.target.rawValue;
    },
    validateCpf(name) {
    
      let value = this.form[name];
      let validation = cpf.isValid(value);
      this.validations[name] = validation;
      
      this.shouldShowToolTip(name);
      
      return validation;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      
      this.shouldShowToolTip(name);

      return validation;
    },
    shouldShowToolTip(name) {
      if(this.form.originalData != null) {
        if (this.form[name] != this.form.originalData[name])
          this.tooltip[name] = true;
        else
          this.tooltip[name] = false;
      }    
    },
    shouldNotValidateFiles() {
      return (
        this.form.name == this.form.originalData?.name &&
        this.form.crm == this.form.originalData?.crm &&
        this.form.rg == this.form.originalData?.rg &&
        this.form.pis == this.form.originalData?.pis &&
        this.form.cpf == this.form.originalData?.cpf &&
        this.form.birthdate == this.form.originalData?.birthdate &&
        this.form.nationalHealthCard ==
          this.form.originalData?.nationalHealthCard &&
        (this.form.retiree == this.form.originalData?.retiree ||
         this.form.retiree.id === 'N')
      );
    },
    formTouched() {
      return (
        this.form.name == this.form.originalData?.name &&
        this.form.crm == this.form.originalData?.crm &&
        this.form.maritalStatus == this.form.originalData?.maritalStatus &&
        this.form.schooling == this.form.originalData?.schooling &&
        this.form.ethnicity == this.form.originalData?.ethnicity &&
        this.form.retiree == this.form.originalData?.retiree &&
        this.form.gender == this.form.originalData?.gender &&
        this.form.rg == this.form.originalData?.rg &&
        this.form.pis == this.form.originalData?.pis &&
        this.form.cpf == this.form.originalData?.cpf &&
        this.form.birthdate == this.form.originalData?.birthdate &&
        this.form.nationalHealthCard ==
          this.form.originalData?.nationalHealthCard
      );
    },
    validateFieldFile(name) {
      if (this.shouldNotValidateFiles()) {
        this.validations[name] = true;
        return true;
      }
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) {
        if (
          this.form.originalData != null &&
          this.form[name] != this.form.originalData[name]
        ) {
          return validation;
        }
        return null;
      }
      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmitHandler() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.$props.onSubmit(this.form);
      }
    },
    buildSchoolingOptions() {
      const schoolingOptions = this.$props.loadedFormData.schoolingList;
      this.schoolingOptions = schoolingOptions.map((schooling) => ({
        value: schooling,
        text: schooling.description,
      }));
      this.form.shooling = schoolingOptions.find(
        (b) =>
          b.description.toUpperCase() ==
          this.form.schooling.description.toUpperCase()
      );
    },
    buildEthnicityOptions() {
      const ethnicityOptions = this.$props.loadedFormData.ethnicityList;
      this.ethnicityOptions = ethnicityOptions.map((ethnicity) => ({
        value: ethnicity,
        text: ethnicity.description,
      }));
    },
    buildRetiredOptions() {
      const retireeOptions = this.$props.loadedFormData.retireeList;
      this.form.retiree = retireeOptions.find(
        (b) => b.id.toUpperCase() == this.form.retiree.id.toUpperCase()
      );
      this.retireeOptions = retireeOptions.map((retiree) => ({
        value: retiree,
        text: retiree.description,
      }));
    },
    buildGenderOptions() {
      const genderOptions = this.$props.loadedFormData.genderList;

      this.form.gender = genderOptions.find(
        (b) =>
          b.description.toUpperCase() ==
          this.form.gender.description.toUpperCase()
      );
      this.genderOptions = genderOptions.map((gender) => ({
        value: gender,
        text: gender.description,
      }));
    },
    buildMaritalStatusOptions() {
      const maritalStatusOptions = this.$props.loadedFormData.maritalStatusList;

      this.form.maritalStatus = maritalStatusOptions.find(
        (b) =>
          b.code.toUpperCase() == this.form.maritalStatus.code.toUpperCase()
      );
      this.maritalStatusOptions = maritalStatusOptions.map((maritalStatus) => ({
        value: maritalStatus,
        text: maritalStatus.description,
      }));
    },
    onChangeSchoolingSelect(selectedShooling) {
      const form = { ...this.form, schooling: selectedShooling };
      this.form = form;
    },
    replaceAll(value) {
      const response = value?.replaceAll(" ", "");
      return response;
    },
    onChangeMaritalStatusSelect(selectedMaritalStatus) {
      const form = { ...this.form, maritalStatus: selectedMaritalStatus };
      this.form = form;
    },
    onChangeRaceSelect(selectedRace) {
      const form = { ...this.form, race: selectedRace };
      this.form = form;
    },
    onChangeEthnicitySelect(selectedEthnicity) {
      const form = { ...this.form, ethnicity: selectedEthnicity };
      this.form = form;
    },
    onChangeRetireeSelect(selectedRetiree) {
      const form = { ...this.form, retiree: selectedRetiree };
      this.form = form;
    },
    onChangeGenderSelect(selectedGender) {
      const form = { ...this.form, gender: selectedGender };
      this.form = form;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
.col-tooltip {
  max-width: 0.2rem;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.check-declaration {
  padding-top: 0px;
}
.custom-check {
  margin-top: 8px;
}
.custom-checkbox.b-custom-control-lg {
  margin-top: 3px;
}
.p4 {
  font-size: 1.6rem;
}
.custom-status {
  margin-left: auto;
  margin-right: 0;
}
</style>
