<template>
  <b-container fluid>
    <b-row class="justify-content-center mb-4">
      <p class="p4 color-green-2 justify-content-center">
        Os campos
        <strong
          >Nome, Data de Nascimento, CPF, RG, CRM, PIS/NIT e Cartão Nacional de
          Saúde</strong
        >
        não podem ser alterados de forma automática.
      </p>
      <p class="p4 color-green-2 justify-content-center">
        Mas fique tranquilo(a), você pode solicitar a alteração dessas
        informações a qualquer momento.
      </p>
    </b-row>
    <b-row class="justify-content-center" v-if="!this.alterForm">
      <b-card class="flex-grow-1">
        <div v-show="!this.loading">
          <b-table
            class="custom-table spaced-text"
            :fields="tableFields"
            :items="tableItems"
            stacked
          >
            <template #cell(status_unimed)="">
              <div class="element-padding-left"><status></status></div>
            </template>
          </b-table>
          <div v-if="!tableItems || tableItems.length == 0" class="div-center">
            <h5>Nenhuma informação para exibir!</h5>
          </div>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table
            :rows="tableFields.length"
            :columns="2"
          ></b-skeleton-table>
        </div>
      </b-card>
    </b-row>
    <GeneralInformationForm
      :loadedFormData="tableItems[0]"
      v-if="this.alterForm"
      :onClose="this.onClose"
      :onSubmit="this.onSubmit"
    />

    <b-row class="my-5" v-if="!this.alterForm">
      <b-button
        class="my-3 mr-3"
        variant="primary"
        @click="() => (this.alterForm = true)"
        v-show="this.tableItems.length > 0 && !this.loading"
      >
        Alterar informações
      </b-button>
    </b-row>

    <success-modal
      ref="success-modal"
      description="Suas informações foram alteradas."
      buttonText="Ok, entendi!"
      :protocol="this.protocol"
    >
      <template slot="subdescription">
        <p class="p1">
          <strong>Importante</strong>: Os campos que precisam de análise serão
          atualizados após a validação.
        </p>
      </template>
    </success-modal>
  </b-container>
</template>

<script>
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { getNewGeneralInformation } from "../../../../services/general-information/general-information";
import { getSchoolingOptions } from "../../../../services/schooling/schoolingService";
import GeneralInformationForm from "../forms/general-information/NewGeneralInformationForm.vue";
import { inject } from "@vue/composition-api";

import Status from "../../../../components/status/Status.vue";
import {
  updateGeneralInformation,
  solicitationUpdateGeneralInformation,
} from "../../../../services/general-information/general-information";
import { createToast } from "../../../../components/toast/toast";

export default {
  name: "general-information",
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  components: {
    SuccessModal,
    GeneralInformationForm,
    Status,
  },
  async mounted() {
    await this.getInformation();
  },
  data() {
    return {
      tableFields: [
        {
          key: "status_unimed",
          label: "STATUS NA UNIMED",
          thClass: "text-left",
          tdClass: "text-right",
        },
        {
          key: "name",
          label: "Nome",
          thClass: "text-left",
          tdClass: "text-right",
        },
        { key: "birthdate", label: "Data Nascimento", tdClass: "text-right" },
        { key: "cpf", label: "CPF", tdClass: "text-right" },
        { key: "rg", label: "RG", tdClass: "text-right" },
        {
          key: "maritalStatus.description",
          label: "Estado Civil",
          tdClass: "text-right",
        },
        {
          key: "schooling.description",
          label: "Escolaridade",
          tdClass: "text-right schooling-message",
        },
        { key: "crm", label: "CRM", tdClass: "text-right" },

        { key: "pis", label: "PIS/NIT", tdClass: "text-right" },
        {
          key: "nationalHealthCard",
          label: "Cartão Nacional Saúde",
          tdClass: "text-right",
          thClass: "text-left",
        },
        {
          key: "ethnicity.description",
          label: "RAÇA - COMO VOCÊ SE AUTODECLARA?",
          tdClass: "text-right",
        },
        {
          key: "gender.description",
          label: "SEXO - COMO VOCÊ SE AUTODECLARA?",
          tdClass: "text-right",
        },
        {
          key: "retiree.description",
          label: "VOCÊ ESTÁ APOSENTADO(A)?",
          tdClass: "text-right",
        },
      ],
      tableItems: [],
      protocol: "",
      loading: true,
      alterForm: false,
      mensagem: "0",
      shoolingOptionsData: [],
    };
  },
  methods: {
    onClose() {
      this.alterForm = false;
    },
    async onSubmit(formData) {
      try {
        this.globalLoading = true;
        const crm = this.$store.getters.getUserName;
        this.protocol = await solicitationUpdateGeneralInformation(
          formData,
          formData.files
        );
        await updateGeneralInformation(crm, formData);
        await this.getInformation(crm);
        this.onClose();
        this.globalLoading = false;
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
      } catch (e) {
        this.globalLoading = false;
        console.error("error on AlterForm onSubmit: ", e);
        createToast("Atenção", "Ocorreu um erro ao salvar as informações.");
      }
    },
    async getInformation() {
      try {
        const crm = this.$store.getters.getUserName;
        this.loading = true;
        this.genderOptionsData = [
          { id: "M", description: "Masculino" },
          { id: "F", description: "Feminino" },
        ];
        const retireeList = [
          { id: "S", description: "Sim" },
          { id: "N", description: "Não" },
        ];
        let information = await getNewGeneralInformation(crm);
        information = {
          ...information,
          crm,
          ethnicity: information.ethnicityList.find(
            (e) =>
              e.description.toUpperCase() == information.ethnicity.toUpperCase()
          ),
          genderList: this.genderOptionsData,
          gender: this.genderOptionsData.find(
            (g) =>
              g.description.toUpperCase() == information.gender.toUpperCase()
          ),
          schooling: information.schoolingList.find(
            (s) =>
              s.description.toUpperCase() == information.schooling.toUpperCase()
          ),
          maritalStatus: information.maritalStatusList.find(
            (m) =>
              m.code.toUpperCase() ==
              information.codeMaritalStatus.toUpperCase()
          ),
          retiree: retireeList.find((r) => r.id == information.retiree),
          retireeList,
        };
        this.tableItems = [information];
        this.loading = false;
      } catch (error) {
        console.error("getInformation", error);
        this.loading = false;
      }
    },
    async getSchooling(crm) {
      try {
        this.shoolingOptionsData = await getSchoolingOptions(crm);
      } catch (error) {
        console.error("getSchooling", error);
      }
    },
  },
};
</script>

<style lang="scss">
div.card {
  border-color: transparent;
  border-radius: 1rem;
}

.div-center {
  text-align: center;
  border-radius: 2rem;
}
.schooling-message {
  text-transform: lowercase;
}
.schooling-message::first-letter {
  text-transform: uppercase;
}
</style>
